<template>
    <div>
        <div class="commonInputTopBox">
            <infoHeader
                :content_name="'电梯生产商产品列表'"
            />
            <div style="float: right; margin-top: -65px">
                <el-button type="primary" @click="dialogFormVisibleAdd = true"
                    >新增升降机生产商产品</el-button
                >
                <!-- <el-button type="danger" @click="deleteProduct"
                    >删除该产品</el-button
                > -->
            </div>
        </div>
        
        <div class="commonControl-body">
            <common-table
                :tableData="tableData"
                :tableButton="tableButton"
                :tableLabel="tableLabel"
                @edit="editFirstLevel"
            />
        </div>
        <!-- 编辑产品对话框 -->
        <el-dialog title="编辑产品" :visible.sync="dialogFormVisibleEdit">
            <el-form :model="formEdit">
                <el-form-item label="产品id" label-width="120px">
                    <el-input v-model="formEdit.id" :disabled="true" autocomplete="off" />
                </el-form-item>
                <el-form-item label="产品系列" label-width="120px">
                    <el-input v-model="formEdit.product_series" autocomplete="off" />
                </el-form-item>
                <el-form-item label="产品分类" label-width="120px">
                    <el-select v-model="optionValue" placeholder="请选择">
                        <el-option v-for="item in value" :key="item.id" :label="item.product_type_name" :value="item.id" />
                    </el-select>
                </el-form-item>
                <el-form-item label="产品名" label-width="120px">
                    <el-input v-model="formEdit.product_name" autocomplete="off" />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisibleEdit = false">取 消</el-button>
                <el-button type="primary" @click="updateFirstLevel">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 新增生产商产品对话框 -->
        <el-dialog title="新增升降机生产商产品" :visible.sync="dialogFormVisibleAdd">
            <el-form :model="formAdd">
                <el-form-item label="名称" label-width="120px">
                    <el-input v-model="formAdd.lifter_name" autocomplete="off" />
                </el-form-item>
                <el-form-item label="型号" label-width="120px">
                    <el-input v-model="formAdd.lifter_model" autocomplete="off" />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisibleAdd = false">取 消</el-button>
                <el-button type="primary" @click="createFirstLevel">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import {
    product_edit,
    product_classify_get
} from '@/api/manage/operation/product/product.js';
import { lifter_product_query,lifter_product_create} from '@/api/manage/operation/lifter_manage/lifter_manage.js';
import CommonTable from 'frontelementpackage/src/CommonTableButtonFixed.vue';

export default {
    name: 'lift_manufacturer_product',
    data() {
        return {
            query: {
                querypage: 1,
                pagesize: 10,
                keyword: '',
                is_delete: false,
                total: 0,
            },
            tableData: [],
            dialogFormVisibleEdit: false,
            formEdit: { group_info: '' },
            dialogFormVisibleAdd: false,
            formAdd: {},
            tableLabel: [
                { prop: 'id', label: 'id', minWidth: '20%' },
                { prop: 'lifter_name', label: '升降机产品名称', minWidth: '20%' },
                { prop: 'lifter_model', label: '升降机产品型号', minWidth: '20%' },
                { prop: 'lifter_producer_id', label: '升降机产品所属生产商id', minWidth: '20%' }
            ],
            tableButton: {
                width: 300,
                data: [{ name: '详情', type: 'info', size: 'small' }]
            },
            value: null,
            optionValue: null,
            formConfig: [
                { label: '关键字', prop: 'keyword', component: 'el-input', placeholder: '请输入关键词' },
                { 
                    label: '产品状态', 
                    prop: 'is_delete', 
                    component: 'el-select', 
                    placeholder: '请选择状态', 
                    options: [
                        { label: '未删除', value: 'false' },
                        { label: '已删除', value: 'true' }
                    ] 
                },
                { 
                    label: '产品分类', 
                    prop: 'product_type', 
                    component: 'el-select', 
                    placeholder: '请选择分类', 
                    options: [
                        { label: '设备', value: 1 },
                        { label: '软件', value: 2 }
                    ] 
                }
            ],
            formModel: {},
            producer_id:null
        };
    },
    components: {
        CommonTable,
    },
    created() {
        this.producer_id = this.$route.query.id
        this.getFirstLevel(this.producer_id);
        this.initOption();
    },
    methods: {
        initOption() {
            product_classify_get({}).then(res => {
                if (res.code === 200) {
                    this.value = res.data;
                } else {
                    this.$message.info(res.msg);
                }
            });
        },
        editFirstLevel(row, methods) {
            if (methods === '详情') {
                this.gotoUser(row);
            }
        },
        gotoUser(row) {
            this.$router.push({ name: 'lifter_Manufacturer_Product_Info', query: row });
        },
        getFirstLevel(id) {
            lifter_product_query({producer_id:id}).then(res => {
                this.tableData = res.data;
            });
        },
        createFirstLevel() {
            lifter_product_create({lifter_producer_id:this.producer_id,...this.formAdd}).then(res => {
                if (res.code === 200) {
                    this.$message.success('成功添加');
                    this.$router.go(0);
                } else {
                    this.$message.info(res.msg);
                }
            });
            this.dialogFormVisibleAdd = false;
        },
        updateFirstLevel() {
            this.formEdit.product_id = this.formEdit.id;
            delete this.formEdit.id;
            this.formEdit.product_type = this.optionValue;
            product_edit(this.formEdit).then(res => {
                if (res.code === 200) {
                    this.$message.success('成功修改');
                    this.getFirstLevel();
                } else {
                    this.$message.info(res.msg);
                }
            });
            this.dialogFormVisibleEdit = false;
        },
        handleFormSubmit(formData) {
            Object.assign(this.query, formData);
            this.getFirstLevel();
        },
        handleButtonClick(button, formData) {
            if (button.action === 'search') {
                this.handleFormSubmit(formData);
            } else if (button.action === 'create') {
                this.dialogFormVisibleAdd = true;
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
